import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { StreakService } from '../../service/streak.service';
import { StreakInfo } from '../../model/streak.model';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-streak-display',
  templateUrl: './streak-display.component.html',
  styleUrls: ['./streak-display.component.scss'],
})
export class StreakDisplayComponent implements OnInit, OnChanges {
  @Input() public state: ComponentState = COMPONENT_STATE.LOADING;
  @Input() public streakInfo?: StreakInfo;
  @Input() public autoLoad: boolean = true;

  @Output() public readonly loadCompleted = new EventEmitter<StreakInfo>();

  @Input() public justify: 'center' | 'between' | 'around' | 'end' | 'start' = 'center';

  public currentStreakCount = 1;
  public bestStreakCount = 1;

  constructor(private streakService: StreakService) {}

  ngOnInit(): void {
    if (this.autoLoad) this.streakService.get().subscribe(streak => this.loadStreak(streak));
  }

  ngOnChanges(): void {
    if (this.streakInfo) this.loadStreak(this.streakInfo);
  }

  loadStreak(streakInfo: StreakInfo): void {
    this.state = COMPONENT_STATE.NONE;

    this.loadCompleted.emit(streakInfo);

    const { bestStreakCount, currentStreakCount } = this.streakService.loadStreakInfo(streakInfo);

    this.bestStreakCount = bestStreakCount;
    this.currentStreakCount = currentStreakCount;
  }
}

import { Component, Input } from '@angular/core';
import { ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-streak-info',
  templateUrl: './streak-info.component.html',
  styleUrls: ['./streak-info.component.scss'],
})
export class StreakInfoComponent {
  @Input() public count?: number;
  @Input() public type!: 'current' | 'best' | 'old_best' | 'new_current';

  @Input() public state?: ComponentState;

  public readonly typeText = {
    current: 'streak.current',
    best: 'streak.best',
    new_current: 'streak.best',
    old_best: 'streak.old_best',
  };

  public readonly typeIcon = {
    current: '/assets/streak/streak_blue.svg',
    new_current: '/assets/streak/streak_blue.svg',
    best: '/assets/streak/streak_yellow.svg',
    old_best: '/assets/streak/streak_yellow.svg',
  };

  // @Input() public newRecord?: boolean;

  // public readonly typeTextNewRecord = {
  //   current: "Record in corso",
  //   best: "Record precedente"
  // }

  constructor() {}
}

<div [ngClass]="{ disabled: !count || type === 'old_best' }" class="d-flex flex-column justify-content-center align-items-center" style="gap: 0.5em">
  <span [ngClass]="{ 'fut-skeleton': state === 'LOADING' }">
    {{ typeText[type] | transloco }}
  </span>
  <div class="streak-image">
    @if (!state || state === 'NONE') {
      <img [src]="typeIcon[type]" />
    }
    @if (state && state !== 'NONE') {
      <div class="fut-skeleton w-100 h-100"></div>
    }
  </div>
  @if (state && state !== 'NONE') {
    <fut-ghost-fragment [state]="state" class="text-small" text="0 giorni"></fut-ghost-fragment>
  }
  @if (!state || state === 'NONE') {
    <h3 class="fut-bold fut-h3 m-0">
      @if (count && count == 1) {
        <span>{{ 'streak.count.day' | transloco }}</span>
      }
      @if (count && count > 1) {
        <span>{{ 'streak.count.days' | transloco: { count: count } }}</span>
      }
      @if (!count) {
        <span>--</span>
      }
    </h3>
  }
</div>

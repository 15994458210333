import { Component } from '@angular/core';
import { StreakInfo } from '../../model/streak.model';
import { StreakService } from '../../service/streak.service';

@Component({
  selector: 'app-streak-section',
  templateUrl: './streak-section.component.html',
  styleUrls: ['./streak-section.component.scss'],
})
export class StreakSectionComponent {
  public streakInfo?: StreakInfo;

  constructor(private streak: StreakService) {}

  openStreak() {
    this.streak.openDialog(this.streakInfo);
  }
}
